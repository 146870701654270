import { render } from 'react-dom'
import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import AutoSlideObject from "./slidesInfo";
import './styles.css'

// const slides = [
//   { id: 0, url: 'photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i' },
//   { id: 1, url: 'photo-1544572571-ab94fd872ce4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1534&q=80' },
//   { id: 2, url: 'reserve/bnW1TuTV2YGcoh1HyWNQ_IMG_0207.JPG?ixlib=rb-1.2.1&w=1534&q=80' },
//   { id: 3, url: 'photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80' },
// ]
const sleep = t => new Promise(res => setTimeout(res, t));
const App = () => {
  const slow = { mass: 1, tension: 280, friction: 60 }
  const { slideData } = AutoSlideObject();
  const [index, set] = useState(0)
  // const current_bgimage='"'+slideData[index].name+'"';
  const transitions = useTransition(slideData[index], item => item.id, {
  key: slideData[index],
  from: { position: "absolute", opacity: 0 },
  
  enter: { opacity: 1 },
  leave: i => async next => {
    await sleep(100);
    await next({ opacity: 0.5});
  },
  config:{ duration:15000
      // tension: 30,
      // friction: 250,
      // velocity: -0.2
    },
    trail:6950
  
  })
  useEffect(() => void setInterval(() => set(state => (state + 1) % slideData.length),7500), [])
  // useEffect(() => {
  //   const t = setInterval(
  //     () => set((state) => (state + 1) % slideData.length),
  //     7500
  //   );
  //   return () => clearTimeout(t);
  // }, []);
  return transitions.map(({ item, props, key }) => (
    <animated.div
      key={key}
      className="bg"
      // opacity= [{value: 0, duration: 1000},{value: 1, duration: 2000},{value: 0, duration: 1000}]
      style={{ ...props, backgroundImage: `url(`+'"'+`${item.name}`+'"'+`)` }}
    />
  ))
}

render(<App />, document.getElementById('root'))