function importAll(r) {
    const images =r.keys().map(r);
    var newimages=[];
    for (const file of images) {
        // console.log(file)
        newimages.push({'id':images.indexOf(file),'name':file})
        }
    return  newimages    
  }
  


const SlidesInfo = () => {
    
    const slideData = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));
    //  [
    //     {
    //         caption: "Weekends are a bit like rainbows; they look good from a distance but disappear when you get up close to them.",
    //         author:"-John Shirley",
    //         image: "https://cdn.pixabay.com/photo/2016/02/05/10/18/painter-1180676_960_720.jpg"
    //     },
    //     {
    //         caption: "Did you ever notice that when you blow in a dog’s face he gets mad at you? But when you take him in a car, he sticks his head out the window.",
    //         author:"-Steve Bluestone",
    //         image: "https://images.pexels.com/photos/1629781/pexels-photo-1629781.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    //     },
    //     {
    //         caption: "You'll live to be a hundred if you give up all the things that make you want to.",
    //         author:"-The movie Interiors",
    //         image: "https://images.pexels.com/photos/3979650/pexels-photo-3979650.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    //     },
    //     {
    //         caption: "Be careful not to do your good deeds when there's no one watching you.",
    //         author:"-Tom Lehrer",
    //         image: "https://images.pexels.com/photos/3732891/pexels-photo-3732891.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    //     },
    //     {
    //         caption: "To a teacher of languages there comes a time when the world is but a place of many words and man appears a mere talking animal not much more wonderful than a parrot.",
    //         author:"-Joseph Conrad",
    //         image: "https://images.pexels.com/photos/1165082/pexels-photo-1165082.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    //     },
    //     {
    //         caption: "Ideas are easy. It's the execution of ideas that really separates the sheep from the goats.",
    //         author:"-Sue Grafton",
    //         image: "https://images.pexels.com/photos/1011629/pexels-photo-1011629.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    //     }
    // ]

    console.log( {
        slideData,
    })
  
    return {
        slideData,
    };
}

export default SlidesInfo